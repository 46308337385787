import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "data-bs-target"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "offcanvas-header" }
const _hoisted_4 = { class: "d-flex flex-fill justify-content-between align-items-center" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = {
  class: "offcanvas-title text-pagesubtitle",
  id: "staticBackdropLabel"
}
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "overflow-scroll" }
const _hoisted_10 = {
  key: 0,
  class: "offcanvas-body"
}
const _hoisted_11 = {
  key: 1,
  class: "offcanvas-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      hidden: "",
      id: `${_ctx.id}_offcanvas_button`,
      type: "button",
      "data-bs-toggle": "offcanvas",
      "data-bs-target": `#${_ctx.id}`,
      "aria-controls": "staticBackdrop"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: "offcanvas offcanvas-end",
      tabindex: "-1",
      id: _ctx.id,
      "aria-labelledby": "staticBackdropLabel",
      "data-bs-backdrop": "static"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("i", {
              class: "fa-solid fa-chevron-circle-left display-5 text-primary25 cursor-pointer",
              style: {"left":"20px"},
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.canvasBackPressed && _ctx.canvasBackPressed(...args)))
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.title), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("i", {
              class: "fa-solid fa-times display-5 text-primary25 cursor-pointer",
              style: {"left":"20px"},
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeOffCanvas && _ctx.closeOffCanvas(...args)))
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.$slots.body)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _renderSlot(_ctx.$slots, "body")
          ]))
        : _createCommentVNode("", true),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _renderSlot(_ctx.$slots, "footer")
          ]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ]))
}