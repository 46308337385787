<template>
  <ModalComponent :id="settingsModalId" :modalClass="'modal-xl text-primary'" :title="info.screenTitle">
    <template v-slot:body>
      <LoadingContainer v-if="loading" />
      <div v-else v-html="text"></div>
    </template>
  </ModalComponent>
</template>
<script lang="ts">
import { toggleModal } from '@/components/ModalComponent.vue'
import { defineComponent } from "vue";
export default defineComponent({
  mounted() {
    this.$eventBus.$on('showSettingsModal', (data: any) => {
      this.text = ''
      this.key = data.key
      toggleModal(this.settingsModalId)
      this.getTerms()
    })
  },
  data() {
    return {
      text: '',
      key: '',
      settingsModalId: 'settings-modal',
      loading: false
    }
  },
  computed: {
    info() {
      let screenTitle = ''
      let key = ''
      switch (this.key) {
        case 'terms':
          screenTitle = 'Termos de uso'
          key = 'terms-of-use'
          break;
        case 'policy':
          screenTitle = 'Politica de privacidade'
          key = 'privacy-policy'
          break;
      }
      return {
        screenTitle,
        key
      }
    }
  },
  methods: {
    async getTerms() {
      try {
        this.loading = true
        const res = await this.$userService.getSettings(this.info.key)
        this.text = res.data?.terms_of_use || res.data || ''
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  }
})
</script>

<style></style>
