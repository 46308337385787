import moment from "moment"
import NetworkService, { Endpoint } from "./NetworkService"
import { useMainStore } from "@/store"
import RescheduleService from "./RescheduleService"
import PaymentService from "./PaymentService"

export default class AppointmentService {
	network
	rescheduleService
	constructor() {
		this.network = new NetworkService()
		this.rescheduleService = new RescheduleService()
	}

	static TYPES = {
		IMEDIATE: 'imediate',
		SCHEDULED: 'scheduled'
	}
	static EVENTS = {
		CALL_ENDED: 'call-ended'
	}
	static STATUS = {
		AGUARDANDO: "aguardando",
		AGENDADO: "agendado",
		CONCLUIDO: "concluido",
		CANCELADO: "cancelado",
		RECUSADO: "recusado",
		ANDAMENTO: "em andamento",
	}
	static FREQUENCY = {
		SINGLE: "daily",
		WEEKLY: "weekly",
		FORTNIGHT: "fortnight",
		MONTHLY: "monthly",
	}

	list(params: any = {}) {
		const state = useMainStore()
		var session = state.session
		if (params.cpf) {
			params.cpf = params.cpf.clear()
		}
		const p = {
			...params,
			user_id: session?.id,
		}
		return this.network.get(Endpoint.appointments, p)
	}
	show(id: any) {
		return this.network.get(`${Endpoint.appointments}/${id}`)
	}
	getAppointmentByCallid(callId: any) {
		return this.network.get(`${Endpoint.appointmentByCallId}/${callId}`)
	}
	update(info: any) {
		const params = {
			...info,
		}
		delete params.id
		var url = `${Endpoint.appointments}/${info.id}`
		return this.network.put(url, params)
	}
	getPostCategories() {
		return this.network.get(Endpoint.postsCategories)
	}
	getOccupationAreas(params = {}) {
		return this.network.get(`${Endpoint.occupationAreas}`, params)
	}
	getApproaches() {
		return this.network.get(`${Endpoint.approaches}`)
	}
	findPsychologists(params: any = {}) {
		let q = { ...params }
		if (params.q) {
			q.search = params.q
		}
		if (params.page) {
			q.page = params.page
			if (params.page == 1) {
				q.seed = moment().valueOf()
			}
		}
		return this.network.get(Endpoint.findPsychologists, q)
	}
	rateAppointment(id: any, like: boolean) {
		return this.network.post(`${Endpoint.appointments}/${id}/like`, {
			appointment_id: id,
			like: like,
		})
	}
	reportPsychologist(id: any, message: string) {
		return this.network.post(Endpoint.appointmentReport, {
			appointment_id: id,
			content: message,
		})
	}
	makeAppointment(info: any) {
		var params = {
			...info,
			stripe_source_account: 'new'
		}
		return this.network.post(Endpoint.appointments, params)
	}
	createEvent(params: any) {
		return this.network.post(Endpoint.callEvents, params)
	}
	updateReschedule(rescheduleId: any, params: any) {
		return this.network.put(`${Endpoint.reschedule}/${rescheduleId}`, params)
	}

	paymentRetry(appointmentId: String) {
		const state = useMainStore()
		var session = state.session
		return this.network.post(`${Endpoint.appointments}/${appointmentId}/payment-retry`, { card_id: session.default_payment_method?.info?.card_id })
	}
	cancelRecurrency(recurrencyId: any, reason: string) {
		return this.network.delete(`${Endpoint.appointmentRecurrencies}/${recurrencyId}`, { reason })
	}

	checkRecurrency(params: any) {
		return this.network.post(Endpoint.checkRecurrency, params)
	}


	generateMonth(availableWeekDays: any, date: any) {

		function dayIsDisabled(day: any) {
			// true -> dia desabilitado
			// false -> dia habilitado
			if (day.isSameOrBefore(moment(), 'day')) return true // se for no passado ou hoje, desabilita
			//se nao estiver no dia da semana setado pelo psi, desabilita
			return !availableWeekDays.includes(moment(day).weekday())
		}
		let firstAvailableDay = null
		try {
			const startOfMonth = date.clone().startOf('month')
			const startOfWeek = startOfMonth.clone().startOf('week')
			const today = moment().format('YYYY-MM-DD')

			var currDay = startOfWeek
			var monthWeeks = [[], [], [], [], [], []] as any

			for (let i = 0; i < 6; i++) {
				// semanas
				for (let j = 0; j <= 6; j++) {
					// dias
					const formattedDate = currDay.format('YYYY-MM-DD')
					const isCurrentMonth = date.format('YYYY-MM') == currDay.format('YYYY-MM')
					const disabled = dayIsDisabled(currDay) || !isCurrentMonth
					if (!firstAvailableDay && !disabled) {
						firstAvailableDay = currDay.format('YYYY-MM-DD')
					}
					monthWeeks[i].push({
						date: currDay,
						currentDate: moment(formattedDate),
						formattedDate,
						day: currDay.format('D'),
						isToday: today == formattedDate,
						disabled
					})
					currDay.add(1, 'days')
				}
			}

		} catch (e) {
			console.error(e)
			return {
				date: moment(),
				firstAvailableDay,
				month: []
			}
		}
		return {
			date,
			firstAvailableDay,
			month: monthWeeks
		}
	}

	async getAvailableDays(psiId: any) {
		let res = await this.network.get(Endpoint.availableDays, { user_id: psiId })
		var weeks = [] as any[]
		res.data.map((r: any) => {
			switch (r.weekday) {
				case "sunday":
					weeks.push(0)
					break
				case "monday":
					weeks.push(1)
					break
				case "tuesday":
					weeks.push(2)
					break
				case "wednesday":
					weeks.push(3)
					break
				case "thursday":
					weeks.push(4)
					break
				case "friday":
					weeks.push(5)
					break
				case "saturday":
					weeks.push(6)
					break
			}
		})


		const monthsQtd = 6 // quantidade de meses que vai gerar
		const referenceDate = moment()
		const firstMonth = moment(referenceDate).add(1, 'day')

		let months = []
		for (let index = 0; index < monthsQtd; index++) {
			const month = this.generateMonth(weeks, moment(firstMonth).add(index, 'month'))
			months.push(month)
		}
		return months
	}
	async getAvailableTimes(date: any, user_id: any) {
		const now = moment()
		const res = await this.network.get(Endpoint.availableTimes, { user_id, date })
		let times = res.data
			.filter((t: any) => moment(t).isAfter(now))
			.map((r: any) => {
				const st = moment(r)
				return {
					date: r,
					formattedDate: `${st.format(`dddd`)} das ${st.format(`HH[h]mm`)} ás ${st
						.add(50, "minutes")
						.format(`HH[h]mm`)}`,
				}
			})
		return {
			status: true,
			data: times,
		}
	}
	getFormattedAppointmentStatus(statusName: string, breakLine = false) {
		switch (statusName) {
			case AppointmentService.STATUS.AGUARDANDO:
				return {
					name: breakLine ? "Aguardando\nconfirmação" : "Aguardando confirmação",
					color: 'text-warning',
					icon: 'fas fa-clock'
				}
			case AppointmentService.STATUS.AGENDADO:
				return {
					name: "Confirmado pelo psi",
					color: 'text-success',
					icon: 'fas fa-check-circle'
				}
			case AppointmentService.STATUS.CONCLUIDO:
				return {
					name: "Finalizada",
					color: 'text-primary50'
				}
			case AppointmentService.STATUS.CANCELADO:
			case AppointmentService.STATUS.RECUSADO:
				return {
					name: "Cancelado",
					color: 'text-primary50'
				}
			case AppointmentService.STATUS.ANDAMENTO:
				return {
					name: "Em andamento",
					color: 'text-info'
				}
			default:
				return {
					name: "Sem status",
					color: 'primary'
				}
		}
	}
	getFormattedRecurrency(recurrency: any) {
		try {
			const frequency = recurrency.frequency == AppointmentService.FREQUENCY.WEEKLY && recurrency.interval == 2 ? AppointmentService.FREQUENCY.FORTNIGHT : recurrency.frequency
			const next_appointment_date = recurrency.next_appointment_date?.date ? recurrency.next_appointment_date.date.toFormattedDate(11) : null
			let frequency_str = ''
			switch (frequency) {
				case AppointmentService.FREQUENCY.SINGLE:
					frequency_str = 'Avulsa'
					break;
				case AppointmentService.FREQUENCY.WEEKLY:
					frequency_str = 'Semanal'
					break;
				case AppointmentService.FREQUENCY.FORTNIGHT:
					frequency_str = 'Quinzenal'
					break;
				case AppointmentService.FREQUENCY.MONTHLY:
					frequency_str = 'Mensal'
					break;
			}
			return {
				frequency,
				frequency_str,
				next_appointment_date
			}
		} catch (e) {
			console.error('getFormattedRecurrency', e)
			return recurrency
		}
	}

	checkRecurrencyAvailability = async (params: any) => {
		if (params.recurrency) {
			const recRes = await this.checkRecurrency({ ...params.recurrency, date: params.date })
			if (!recRes.data.can_schedule) {
				throw { title: 'Ops! Horário indisponível.', message: 'O horário solicitado coincide com uma sessão já agendada no futuro na agenda do psi.' }
			}
		}
	}

	makeCheckoutParams = () => {
		const state = useMainStore();
		const session = state.session;
		const appointmentCheckout = JSON.parse(JSON.stringify(state.new_appointment_info));
		let params: any = {
			type: appointmentCheckout.type,
			stripe_source_account: "new"
		};


		if (appointmentCheckout.has_valid_partnership && appointmentCheckout.frequency == AppointmentService.FREQUENCY.SINGLE) {
			params.payment_method = PaymentService.PAYMENT_METHODS.partnership
		} else {
			params.payment_method = session?.default_payment_method?.type
			if (params.payment_method == PaymentService.PAYMENT_METHODS.card) {
				params.card_id = session.default_payment_method?.info?.card_id
			}

		}

		if (appointmentCheckout.type == AppointmentService.TYPES.SCHEDULED) {
			params.psychologist_id = appointmentCheckout.psi.id;
			if (appointmentCheckout.online) {
				params.online = true;
			} else {
				params.date = appointmentCheckout.date;
			}
			if (appointmentCheckout.first_appointment) {
				params.first_appointment = true;
			}
			if (appointmentCheckout.coupon) {
				params.code = appointmentCheckout.coupon.code
			}
			if (!appointmentCheckout.online && !appointmentCheckout.first_appointment) {
				if (appointmentCheckout.frequency == AppointmentService.FREQUENCY.FORTNIGHT) {
					// se for 15 dias, é weekly com interval 2
					params.recurrency = {
						frequency: AppointmentService.FREQUENCY.WEEKLY,
						interval: 2
					}
				} else if (appointmentCheckout.frequency != AppointmentService.FREQUENCY.SINGLE) {
					params.recurrency = {
						frequency: appointmentCheckout.frequency,
						interval: 1
					}
				}
			}
		}
		return JSON.parse(JSON.stringify(params));
	};

	callRating = (params: any) => {
		return this.network.post(Endpoint.callRating, params)
	}

	prepareAppointment = (appointment: any) => {
		try {
			if (appointment.psychologist) {
				appointment.psychologist.formatted_profile_image = `${process.env.VUE_APP_BASE_IMG_URL}${appointment.psychologist.profile_image}`
			}
			if (appointment.status) {
				appointment.formattedStatus = this.getFormattedAppointmentStatus(appointment.status)
			}
			if (appointment.ratings) {
				appointment.my_rating = appointment.ratings?.find(
					(elem: any) => elem.rating_by == "user"
				);
				if (appointment.my_rating) {
					appointment.my_rating.trouble = Boolean(appointment.my_rating.trouble)
					appointment.my_rating.call_completed = Boolean(appointment.my_rating.call_completed)
				}
				appointment.hasChat = appointment.chat && appointment.chat?.status == "active" && !appointment.user?.deleted_at
				if (appointment.hasChat) {
					appointment.chat = { ...appointment.chat, psychologist: appointment.psychologist }
				}
			}
			if (appointment.type == AppointmentService.TYPES.IMEDIATE) {
				appointment.formatted_type = "Imediata"
			} else if (appointment.first_appointment) {
				appointment.formatted_type = "Conversa inicial"
			} else {
				appointment.formatted_type = "Agendado"
			}
			if (appointment.reschedule_requests?.length) {
				let sch = appointment.reschedule_requests
				sch = sch[0]
				if (sch.status != RescheduleService.RESCHEDULE_STATUS.CANCELED) {
					const madeByPsi = sch.user_id === appointment.psychologist_id
					sch = {
						...sch,
						...this.rescheduleService.getFormattedRescheduleStatus(sch.status, madeByPsi),
						madeByPsi
					}
					appointment.formattedReschedule = sch
				}
			}
			appointment.hasRecurrency = !!(appointment.recurrency_id && appointment.recurrency)
			if (appointment.hasRecurrency) {
				appointment.formattedRecurrency = this.getFormattedRecurrency(appointment.recurrency)
			}

			appointment.showCallLink = appointment.can_enter || (appointment.can_enter && (appointment.status == AppointmentService.STATUS.ANDAMENTO || appointment.status != AppointmentService.STATUS.AGUARDANDO))
			appointment.paymentError = appointment.pg_status == 'requires_payment_method'

		} catch (e) {
			console.error('prepareAppointment', e)
		} finally {
			return appointment
		}
	}
}
