<template>
  <OffcanvasComponent :id="`offcanvas_views`" :title="title" @canvasBackPressed="canvasBackPressed">
    <div class="p-4">
      <component :is="View" v-bind="viewProps" />
    </div>
  </OffcanvasComponent>
</template>

<script lang="ts">
import OffcanvasViewsController from "./OffcanvasViewsController";
export default OffcanvasViewsController;
</script>
