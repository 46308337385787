import { sortCols } from '@/utils/Helper'
import qs from 'qs'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ListControllerMixin',
    // created() {
    // },
    mounted() {
        if (this.controlFiltersByUrl) {
            this.filterQueryParams.map((elem: string) => {
                this.filters[elem] = this.$route.query ? (this.$route.query[elem] || '') : ''
                this.afterFilterChangedCallback()
            })
            this.$watch('filters', (newValue, oldValue) => {
                this.$router.push(`${this.$route.path}?${qs.stringify(newValue)}`)
            }, {
                deep: true,
            })
            this.$watch('$route.query', (newValue, oldValue) => {
                this.loadingItems = true
                this.getData()
            }, {
                deep: true,
            })
        }
        this.loadingItems = true
        this.getData()
    },
    data() {
        return {
            filterQueryParams: [] as string[],
            controlFiltersByUrl: false,
            totalItemsRegistered: 0,
            items: [] as any[],
            countItemsShowing: 0,
            countItemsTotal: 0,
            moreItemsToLoad: true,
            loadingMoreItems: false,
            loadingItems: false,
            filters: {
                page: 1,
                q: ''
            } as any,
            columns: [] as any[]
        }
    },
    methods: {
        async getData(loadingMore = false) {
            try {
                if (!loadingMore) {
                    this.filters.page = 1
                }
                const res: any = await this.fetchPromise()
                const data = this.prepareResponse(res.data)
                if (!loadingMore) {
                    this.items = data
                } else {
                    this.items = this.items.concat(data)
                }
                this.moreItemsToLoad = res.next_page_url != null
                this.countItemsShowing = res.to || 0
                this.countItemsTotal = res.total

                if (this.filters.page == 1 && this.filters.q == '') {
                    // so seta o o totalzao quando nao tem filtro
                    this.totalItemsRegistered = res.total
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.loadingItems = false
                this.loadingMoreItems = false
                this.columns = this.columns.map(c => { return { ...c, loading: false } })
            }
        },
        /**
           * @return promise da lista que vc quer chamar
           * -fazer o override na tela pra chamar a funcao/service certo
           */
        fetchPromise() {
            throw new Error('Método não implementado')
        },
        afterFilterChangedCallback() {
            // se eu quiser pegar o que veio da url pra preencher um formulario que nao tem a ver com os filtros, faço aqui
        },
        /**
             * @return faz as mudanças na resposta e retorna pro getData
             * -fazer o override na tela pra tratar a resposta conforme necessario
             */
        prepareResponse(data: any) {
            return data
        },
        loadMore() {
            this.loadingMoreItems = true
            this.filters.page = this.filters.page + 1
            this.getData(true)
        },
        search(string: string) {
            this.filters.q = string
            if(!this.controlFiltersByUrl) {
                this.loadingItems = true
                this.getData()
            }
        },
        sort(colIndex: number | string) {
            this.columns = sortCols(this.columns, colIndex)
            this.getData()
        }
    }
}) 