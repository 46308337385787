

import { IOffcanvasConfig } from '@/components/Offcanvas/OffcanvasViews/OffcanvasViewsController'
import { defineStore } from 'pinia'

export interface IState {
  session: any | null
  access_token: string
  new_appointment_info: any | null
  register_info: any | null,
  offcanvas_config: IOffcanvasConfig | null
  offcanvas_history: IOffcanvasConfig[]
}

function getStateFromStorage(): IState {
  const localState = localStorage.getItem(process.env.VUE_APP_API_STATE_KEY as string)
  const s: IState = JSON.parse(localState || '{}')
  let st: IState = {
    session: s.session,
    access_token: s.access_token,
    new_appointment_info: s.new_appointment_info,
    register_info: s.register_info,
    offcanvas_config: s.offcanvas_config,
    offcanvas_history: s.offcanvas_history || []
    // offcanvas_history: []
  }
  return st
}
function updateStateStorage(params: IState): void {
  localStorage.setItem(process.env.VUE_APP_API_STATE_KEY as string, JSON.stringify(params))
}


export const useMainStore = defineStore('main', {
  state: (): IState => getStateFromStorage(),
  actions: {
    updateState(params: Partial<IState>) {
      const updatedState = { ...this.$state, ...params }
      this.$state = updatedState
      updateStateStorage(updatedState)
    },
    saveSession(params: any) {
      const updatedState = { ...this.$state, session: params }
      this.$state = updatedState
      updateStateStorage(updatedState)
    },
    saveAuthToken(token: string) {
      const updatedState = { ...this.$state, access_token: token }
      this.$state = updatedState
      updateStateStorage(updatedState)
    },
    clearState() {
      const updatedState = {
        session: null,
        access_token: '',
        new_appointment_info: null,
        register_info: null,
        offcanvas_config: null,
        offcanvas_history: []
      }
      this.$state = updatedState
      updateStateStorage(updatedState)
    },
    updateSession(params: any) {
      let sessionParams = {
        ...this.$state.session,
        ...params
      }
      this.updateState({ session: sessionParams })
    },
    updateNewAppointmentInfo(params: any | null, startNew = false) {
      let appInfoParams = null
      if (params !== null) {
        if (params.date) {
          params.date = params.date === "online" ? null : params.date;
        }
        appInfoParams = startNew ? params : {
          ...this.$state.new_appointment_info,
          ...params
        }
      }
      this.updateState({ new_appointment_info: appInfoParams })
    },
    updateRegisterInfo(params: any | null, startNew = false) {
      let regInfo = null
      if (params !== null) {
        regInfo = startNew ? params : {
          ...this.$state.register_info,
          ...params
        }
      }
      this.updateState({ register_info: regInfo })
    },
    saveOffcanvasConfig(config: IOffcanvasConfig) {
      let history: IOffcanvasConfig[] = JSON.parse(JSON.stringify(this.$state.offcanvas_history))
      if (history.length == 0) {
        history = []
      }
      history.push(config)
      const updatedState = { ...this.$state, offcanvas_config: config, offcanvas_history: history }
      this.$state = updatedState
      this.updateState(updatedState)
    },
    popCanvasHistory() {
      let history: IOffcanvasConfig[] = JSON.parse(JSON.stringify(this.$state.offcanvas_history))
      const last = history.pop()
      if (last) {
        const updatedState = { ...this.$state, offcanvas_config: history[history.length - 1] || null, offcanvas_history: history }
        this.$state = updatedState
        this.updateState(updatedState)
      }
    },
    clearOffcanvasConfig() {
      this.updateState({ offcanvas_config: null, offcanvas_history: [] })
    },
  }
})
