<template>
  <div>
    <div class="row">
      <div class="col-12">
        <TextInput required label="Nome completo" :model="v$.form.name" type="text" placeholder="Qual seu nome?"
          :errorMsg="v$.form.name.required?.$message" />
      </div>
      <div class="col-12">
        <TextInput required label="Apelido" :model="v$.form.nickname" type="text" placeholder="Qual seu apelido?"
          :errorMsg="v$.form.nickname.required?.$message" />
      </div>
      <div class="col-12">
        <DocumentInput @valueChanged="documentChanged" />
      </div>
      <div class="col-12 col-md-6">
        <SelectInput required @selectChanged="countryCodeChanged" :model="v$.form.country_code" :options="phoneCodes"
          label="DDI" propertyForValue="dial_code" propertyForText="label" />
      </div>
      <div class="col-12 col-md-6">
        <TextInput required label="Telefone (Whatsapp)" :model="v$.form.phone" type="text" :mask="phoneMask"
          placeholder="Qual seu telefone?" :errorMsg="v$.form.phone.required?.$message" />
      </div>
      <div class="col-12 text-center">
        <span class="text-danger text-center">{{ errorMessage }}</span>
      </div>
    </div>
    <FormButton class="w-100" label="Concluir" :disabled="v$.$invalid" @onClick="handleAccountUpdate" :form="v$.form"
      :marginVertical="false" :loading="submitting" />
  </div>
</template>

<script>
import AccountUpdateController from "./AccountUpdateController";
export default AccountUpdateController;
</script>
