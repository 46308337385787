import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAgjhMXLwtegBTRMjhAdMLFDARBS9We8RM",
  authDomain: "psiapp-44a08.firebaseapp.com",
  projectId: "psiapp-44a08",
  storageBucket: "psiapp-44a08.appspot.com",
  messagingSenderId: "1084629413864",
  appId: "1:1084629413864:web:cd9988a5bc5361c7307e5d",
  measurementId: "G-74KH2VDPR1"
};
export interface IFirebaseCustomEvent {
  name: EFirebaseCustomEventName,
  data: any
  app?: string
}
export enum EFirebaseCustomEventName {
  new_registration = 'new_registration',
  new_appointment = 'new_appointment'
}
export function startFirebase() {
  if (process.env.VUE_APP_ENV_NAME != 'PROD') return
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app)
}
export function sendEvent(eventData: IFirebaseCustomEvent) {
  if (process.env.VUE_APP_ENV_NAME != 'PROD') return
  eventData.data.app = 'psi_web_pacientes'
  logEvent(getAnalytics(), eventData.name as string, eventData.data)
}