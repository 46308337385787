import { useMainStore } from '@/store';
import useVuelidate from '@vuelidate/core';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';
export default defineComponent({
  setup() {
    const store = useMainStore()
    const { session } = storeToRefs(store)
    return { v$: useVuelidate(), userSession: session }
  },
  mounted() {

  },
  data() {
    return {
      code: '',
      submitting: false,
    };
  },
  methods: {
    handleGetInTouch() {
      const whatsappText = 'Olá, gostaria de saber mais sobre os benefícios e parcerias para empresas no Psiapp'
      const whatsappLink = `whatsapp://send?text=${whatsappText}&phone=${process.env.PSIAPP_WHATSAPP_NUMBER}`
      const uri = `https://wa.me/${process.env.VUE_APP_PSIAPP_WHATSAPP_NUMBER}?text=${whatsappText}`
      window.open(uri, '_blank');
    },
    async handleSubmit() {
      try {
        this.submitting = true
        const res = await this.$userService.activatePartnership(this.code)
        await this.$userService.syncSession()
        this.code = ''
        this.$eventBus.$emit('showToast', { title: 'Benefício ativado', msg: 'Seu benefício foi ativado com sucesso' })
      } catch (e: any) {
        this.$eventBus.$emit('showErrorToast', { msg: e.message })
      } finally {
        this.submitting = false
      }
    },
    async handleRemove() {
      try {
        this.submitting = true
        const res = await this.$userService.removePartnership(this.userSession?.partnership?.id)
        await this.$userService.syncSession()
        this.$eventBus.$emit('showToast', { title: 'Benefício removido', msg: 'Seu benefício foi removido' })
      } catch (e) {
        console.error(e)
      } finally {
        this.submitting = false
      }
    }

  }
})
