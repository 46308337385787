import axios, { AxiosRequestHeaders } from 'axios'
import router from '@/router'
import paths from '@/router/paths'
import qs from 'qs'
import { useMainStore } from '@/store'

export default interface IApiResponse {
  status: boolean,
  data: any,
  access_token?: string,
  next_page_url?: string,
  per_page?: number,
  total?: number,
  message?: string
}
export enum Endpoint {
  login = 'api/login',
  recoveryPassword = 'api/password/email',
  addresses = 'api/address',
  coupons = 'api/coupons',
  favoriteCoupons = 'api/myfavoritescoupons',
  establishments = 'api/establishments',
  establishmentsMenu = 'api/products',
  favoriteEstablishments = 'api/myfavoritesestablishments',
  cards = 'api/credit-card',
  myOrders = 'api/myorders',
  orders = 'api/orders',
  favoriteCard = 'api/orders',
  transfer = 'api/transfer',
  addCredit = 'api/add-credit',
  users = 'api/users',
  winners = 'api/winners',
  availableDays = 'api/find-psychologists/availableDays',
  availableTimes = 'api/find-psychologists/availableTimes',
  posts = 'api/posts',
  postsCategories = 'api/post-categories',
  homeVideos = 'api/home-videos',
  appointments = 'api/appointments',
  appointmentReport = 'api/appointment-reports',
  chats = 'api/appointment-chats',
  findPsychologists = 'api/find-psychologists',
  occupationAreas = 'api/occupation-area',
  chatParticipants = 'api/chat-participants',
  chatMessages = 'api/appointment-chat-messages',
  pushToken = 'api/pushToken',
  callEvents = 'api/call-event',
  termsOfUse = 'api/terms-of-use',
  callRating = 'api/calls-rating',
  homeInfo = 'api/home-info',
  checkEmail = 'api/users/registeredEmail',
  appCrashes = 'api/app-crashes',
  notifications = 'api/notifications',
  validateCoupon = 'api/check-coupon',
  usersSlug = 'api/users/slug',
  approaches = 'api/approaches',
  reschedule = 'api/reschedule-requests',
  appointmentRecurrencies = 'api/appointment-recurrencies',
  checkRecurrency = 'api/appointment-recurrencies/verification',
  partnership = 'api/partner-patients',
  appointmentByCallId = 'api/whereby-guest-link',
  passwordReset = 'api/password/reset',
  checkPartnership = 'api/check-partnership-limit',
}

export enum EncodeType {
  multipart = 'multipart',
  urlencoded = 'urlencoded',
}

export interface HeadersRequest {
  default_token?: string
  encode_type?: EncodeType
}

export interface ApiRequest {
  endpoint: Endpoint | string,
  params?: Record<string, any>,
  default_token?: string
}


export default class NetworkService2 {
  baseUrl = process.env.VUE_APP_API_BASE_URL
  constructor() { }


  private handlePromise(promise: Promise<any>): Promise<IApiResponse> {
    return promise
      .then((res: any) => this.handleResponse(res))
      .catch((e: any) => this.handleError(e));
  }


  post(request: ApiRequest): Promise<IApiResponse> {
    return this.handlePromise(axios.post(this.baseUrl + request.endpoint, request.params, { headers: this.headers(request) }))
  }
  get(request: ApiRequest): Promise<IApiResponse> {
    let query: any = request.params || {}
    if (query.search) {
      query.q = query.search.trim()
      delete (query.search)
    }
    if (query.orderBy && query.orderBy.length) {
      // query.orderBy = `${query.orderBy[0].sortName}|${query.orderBy[0].order}`
      query.orderByDirection = query.orderBy[0].order // asc ou desc
      query.orderBy = query.orderBy[0].sortName // nome da coluna
    }
    const url = `${this.baseUrl}${request.endpoint}?${qs.stringify(query)}`

    return this.handlePromise(axios.get(url, { headers: this.headers(request) }))
  }

  put(request: ApiRequest): Promise<IApiResponse> {
    return this.handlePromise(axios.put(this.baseUrl + request.endpoint, request.params, { headers: this.headers(request) }))
  }

  delete(request: ApiRequest): Promise<IApiResponse> {
    return this.handlePromise(axios.delete(this.baseUrl + request.endpoint, { params: request.params, headers: this.headers(request) }))
  }

  postEncoded(request: ApiRequest): Promise<IApiResponse> {
    return this.handlePromise(axios.post(this.baseUrl + request.endpoint, qs.stringify(request.params || {}), { headers: this.headers({ default_token: request.default_token, encode_type: EncodeType.urlencoded }) }))
  }

  putEncoded(request: ApiRequest): Promise<IApiResponse> {
    return this.handlePromise(axios.put(this.baseUrl + request.endpoint, qs.stringify(request.params || {}), { headers: this.headers({ default_token: request.default_token, encode_type: EncodeType.urlencoded }) }))
  }

  postMultipart(request: ApiRequest): Promise<IApiResponse> {
    return axios.post(this.baseUrl + request.endpoint, this.makeItMultipartParams(request.params), { headers: this.headers({ default_token: request.default_token, encode_type: EncodeType.multipart }) })
  }

  putMultipart(request: ApiRequest): Promise<IApiResponse> {
    return this.handlePromise(axios.put(this.baseUrl + request.endpoint, this.makeItMultipartParams(request.params), { headers: this.headers({ default_token: request.default_token, encode_type: EncodeType.multipart }) }))
  }

  makeItMultipartParams(params?: Record<string, any>): FormData {
    const p = new FormData()
    if (!params) return p
    Object.keys(params).forEach(function (key, index) {
      if (Array.isArray(params[key])) {
        params[key].map((r: any) => {
          p.append(`${key}[]`, r)
        })
      } else {
        p.append(key, params[key])
      }
    })
    return p
  }



  makeExternalRequest(type: string, endPoint: string, params: any, headers = {}) {
    switch (type) {
      case 'post':
        return axios.post(endPoint, params, { headers })

      case 'get':
        return axios.get(endPoint, { headers })

      case 'put':
        return axios.put(endPoint, params, { headers })
    }
  }

  headers(request: HeadersRequest): AxiosRequestHeaders {
    const store = useMainStore()
    const access_token = store.access_token || request.default_token
    const headers: AxiosRequestHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (request.encode_type == EncodeType.multipart) {
      delete (headers['Content-Type'])
    }
    if (request.encode_type == EncodeType.urlencoded) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    if (access_token) {
      headers.Authorization = `Bearer ${access_token}`
    }
    return headers
  }

  handleResponse(res: any) {
    if (res.data.status === false) {
      this.handleError(res)
    } else {
      return res.data
    }
  }

  handleError(error: any) {
    const e = error.response || error
    console.error('ERRRRRROOOOOOOOOOOOOOOO', e)
    if (e && e.status && e.status == 401) {
      router.push(paths.login)
    }
    let message = e?.data?.message || e?.message || 'Ocorreu um erro, tente novamente'
    if (e?.data?.errors) {
      const keys = Object.keys(e.data?.errors)
      message = e.data?.errors[keys[0]][0]
      console.error('meeeeeeeessage', message)
    }
    throw ({ message })
  }
}
