<template>
  <div>
    <button hidden :id="`${id}_offcanvas_button`" type="button" data-bs-toggle="offcanvas" :data-bs-target="`#${id}`"
      aria-controls="staticBackdrop"></button>
    <div class="offcanvas offcanvas-end" tabindex="-1" :id="id" aria-labelledby="staticBackdropLabel"
      data-bs-backdrop="static">
      <div class="offcanvas-header">
        <div class="d-flex flex-fill justify-content-between align-items-center">
          <div class="">
            <i class="fa-solid fa-chevron-circle-left display-5 text-primary25 cursor-pointer" style="left: 20px"
              @click="canvasBackPressed" />
          </div>
          <div class="">
            <p class="offcanvas-title text-pagesubtitle" id="staticBackdropLabel">
              {{ title }}
            </p>
          </div>
          <div class="">
            <i class="fa-solid fa-times display-5 text-primary25 cursor-pointer" style="left: 20px"
              @click="closeOffCanvas" />
          </div>
        </div>
      </div>

      <div class="overflow-scroll">
        <slot></slot>
      </div>

      <div class="offcanvas-body" v-if="$slots.body">
        <slot name="body"></slot>
      </div>
      <div class="offcanvas-footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { closeOffCanvas } from "./OffcanvasViews/OffcanvasViewsController";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Title",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      closeOffCanvas: closeOffCanvas
    }
  },
  emits: ["canvasBackPressed"],
  methods: {
    canvasBackPressed() {
      this.$emit("canvasBackPressed");
    },
  },
});

export const toggleOffcanvas = (id: string) => {
  const button = document.getElementById(`${id}_offcanvas_button`);
  if (button) {
    button?.click();
  }
};
</script>
