<template>
  <div class="row align-items-center d-flex">
    <div class="col-12 col-md-6">
      <TextInput :required="true" id="register-document" label="Documento" :maxLength="30" :model="v$.documentNumber"
        type="text" :placeholder="foreignDocument ? 'Informe o número do documento' : 'Informe o CPF'"
        :errorMsg="errorMessage" :mask="'************************'" />
    </div>
    <div class="col-12 col-md-6">
      <div class="form-check form-switch mt-1 mb-2">
        <input class="form-check-input" v-model="foreignDocument" type="checkbox" id="register-foreigner-switch">
        <label class="form-label text-muted small mb-0" for="register-foreigner-switch">Resido fora do
          Brasil</label>
      </div>
    </div>
    <span v-if="v$.documentNumber.$invalid" id="error-msg-container"></span>
  </div>
</template>

<script lang="ts">
import { useMainStore } from '@/store';
import { EventBus } from '@/utils/EventBus';
import { isCPFValid } from '@/utils/FormValidators';
import useVuelidate from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';

export type TDocumentInputResponse = {
  values: { document: string, foreigner: boolean },
  valid: boolean,
}

export default defineComponent({
  name: "DocumentInput",
  emits: ['valueChanged'],
  setup() {
    const store = useMainStore()
    const { session } = storeToRefs(store)
    return {
      v$: useVuelidate(),
      userSession: session
    }
  },

  created() {
    this.setupDocument()
    EventBus.$on('accountUpdated', this.setupDocument)
  },
  data() {
    return {
      foreignDocument: false,
      documentNumber: ''
    }
  },
  computed: {
    errorMessage(): string {
      return this.v$.documentNumber.$errors.find((e: any) => e)?.$message || 'Informe um documento válido'
    }
  },

  watch: {
    'v$': {
      handler() {
        this.sendValue()
      },
      deep: true
    }
  },
  methods: {
    sendValue() {
      const response: TDocumentInputResponse = {
        values: { document: this.documentNumber, foreigner: this.foreignDocument },
        valid: !this.v$.documentNumber.$invalid,
      }
      this.$emit('valueChanged', response)
    },
    setupDocument() {
      this.documentNumber = this.userSession?.document_number || ''
      if (!isCPFValid(this.documentNumber)) {
        this.foreignDocument = true
      } else {
        this.foreignDocument = this.userSession?.foreigner || false
      }
    }
  },
  validations() {
    return {
      documentNumber: this.foreignDocument
        ? {
          required: helpers.withMessage("Informe um documento", required),
          minLength: helpers.withMessage("Informe um documento", minLength(3)),
        }
        : {
          required: helpers.withMessage("Informe um CPF para o recibo", required),
          isValidCPF: helpers.withMessage("Informe um CPF válido", (value: any) => isCPFValid(value))
        }
    };
  }
});
</script>
