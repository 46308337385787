import { defineComponent } from 'vue';

export interface IAutocompleteData {
  id: string | number,
  title: string
}

export default defineComponent({
  name: 'AutocompleteInput',
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    errorMsg: {
      type: String,
      default: 'Por favor, preencha este campo corretamente'
    },
    value: {
      type: String,
      default: ''
    },
    validationModel: {
      required: false,
      default: () => { }
    },
    inputStyle: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    dataSet: {
      type: Array,
      default: []
    }
  },
  mounted() {
    this.model = this.value || ''
  },
  computed: {
    filteredResults(): any[] {
      const res = this.searchString(this.dataSet, this.model, 'title')
      return res.filter((r: any) => !r.hidden)
    }
  },
  data() {
    return {
      model: '',
      showResults: false
    }
  },
  watch: {
    model(value) {
      this.$emit('modelChanged', value)
    }
  },
  methods: {
    handleItemSelected(item: IAutocompleteData) {
      this.model = item.title
    },
    hideResults() {
      setTimeout(() => {
        this.showResults = false
      }, 300);
    },

    searchString(originArray: Array<any>, string: string, ...keys: any) {
      try {
        let properties = keys
        if (!string) {
          originArray.map(r => r.hidden = false)
          return originArray
        }
        if (!originArray || originArray.length == 0) return originArray
        if (!properties || properties.length == 0) {
          properties = Object.keys(originArray[0])
        }
        var resultArray = originArray
        resultArray.map(item => {
          let reg = /[\u0300-\u036f]/g
          var itemFound = false
          properties.map((s: any) => {
            if (item[s] && ['string', 'number'].includes(typeof item[s])) {
              if (item[s].toString().toLowerCase().normalize('NFD').replace(reg, '').indexOf(string.toLowerCase().normalize('NFD').replace(reg, '')) > -1) {
                itemFound = true
              }
            }
          })
          item.hidden = !itemFound
        })
        return resultArray
      } catch (e) {
        console.error(e)
        return originArray
      }
    }

  }
})

