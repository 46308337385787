<template>
  <template v-if="rating">
    <!-- Preenchido -->
    <div class="text-primary">
      <h3 class="text-header mb-2">Teve algum problema técnico?</h3>
      <h3 class="text-header">
        <span class="badge me-3 rounded-5" @click="handleTrouble(true)"
          :class="{ 'bg-primary': rating?.trouble, 'bg-primary-alt': !rating?.trouble }">Sim</span>
        <span class="badge" @click="handleTrouble(false)"
          :class="{ 'bg-primary': !rating?.trouble, 'bg-primary-alt': rating?.trouble }">Não</span>
      </h3>
      <h5>{{ rating?.description }}</h5>
      <hr>
      <template v-if="rating?.trouble">
        <h3 class="text-header mb-2">Conseguiu realizar a sessão?</h3>
        <h3 class="text-header">
          <span class="badge me-3" @click="handleCallCompleted(true)"
            :class="{ 'bg-primary': rating?.call_completed, 'bg-primary-alt': !rating?.call_completed }">Sim</span>
          <span class="badge" @click="handleCallCompleted(false)"
            :class="{ 'bg-primary': !rating?.call_completed, 'bg-primary-alt': rating?.call_completed }">Não</span>
        </h3>
        <hr>
      </template>

      <template v-if="rating?.call_completed || !rating?.trouble">
        <div class="border mt-4 p-4 rounded-5 text-primary cursor-pointer">
          <div class="row">
            <div class="col-12 text-center">
              <img :src="appointment?.psychologist?.formatted_profile_image" alt="" class="avatar avatar-xxl rounded">
              <h3 class="text-header mb-2 mt-3">Deixe seu depoimento para <p class="m-0">{{
                appointment?.psychologist.name }}</p>
              </h3>
              <h5>CRP: {{ appointment?.psychologist?.crp }}</h5>
              <!-- <div class="mb-4">
                <StarRating :ratingCount="rating?.psi_rating" />
              </div>  -->
            </div>
            <hr>
            <h5 class="fw-bold mb-2">Gostaria de deixar um depoimento?</h5>
            <h5 class="">{{ rating?.testimonial || 'N/A' }}</h5>
          </div>
        </div>
      </template>
    </div>
  </template>

  <!-- Não Preenchido -->
  <template v-else>
    <h3 class="text-header mb-2 text-primary">Teve algum problema técnico?</h3>
    <h3 class="text-header cursor-pointer">
      <span class="badge me-3 rounded-5" @click="handleTrouble(true)"
        :class="{ 'bg-primary': this.form.trouble, 'bg-primary-alt': !this.form.trouble }">Sim</span>
      <span class="badge" @click="handleTrouble(false)"
        :class="{ 'bg-primary': !this.form.trouble, 'bg-primary-alt': this.form.trouble }">Não</span>
      <input class="form-control border-0 text-primary px-0 mt-2" v-model.trim="v$.form.description.$model" type="text"
        :maxlength="200" placeholder="Gostaria de fazer algum comentário?" />
    </h3>
    <hr>
    <template v-if="this.form.trouble">
      <h3 class="text-header mb-2 ">Conseguiu realizar a sessão?</h3>
      <h3 class="text-header cursor-pointer">
        <span class="badge me-3" @click="handleCallCompleted(true)"
          :class="{ 'bg-primary': this.form.call_completed, 'bg-primary-alt': !this.form.call_completed }">Sim</span>
        <span class="badge" @click="handleCallCompleted(false)"
          :class="{ 'bg-primary': !this.form.call_completed, 'bg-primary-alt': this.form.call_completed }">Não</span>
      </h3>
      <hr>
    </template>
    <template v-if="this.form.call_completed || !this.form.trouble">
      <div class="border mt-4 p-4 rounded-5 text-primary cursor-pointer">
        <div class="row">
          <div class="col-12 text-center">
            <img :src="appointment?.psychologist?.formatted_profile_image" alt="" class="avatar avatar-xxl rounded">
            <h3 class="text-header mb-2 mt-3">Deixe seu depoimento para <p class="m-0">{{ appointment?.psychologist.name
                }} </p>
            </h3>
            <h5>CRP: {{ appointment?.psychologist?.crp }}</h5>
             <!-- <div class="mb-4">
              <StarRating :ratingCount="form?.psi_rating" @onRatingChanged="handleStarChanged" />
            </div> -->
          </div>
          <hr>
          <h5 class="fw-bold mb-2">Gostaria de deixar um depoimento?</h5>
          <input class="form-control border-0 text-primary mt-2" v-model.trim="v$.form.testimonial.$model" type="text"
            :maxlength="200" placeholder="Descreva aqui" />
        </div>
      </div>
    </template>

    <FormButton label="Enviar depoimento" :disabled="v$.$invalid" :form="v$.form" :loading="submitting"
    @onClick="handleSendFeedback" class="flex-1 mt-3" />
    
    <button @click="$emit('onRatingFinished')" class="btn btn-outline-primary w-100 mt-3">
      Pular
    </button>
  </template>
</template>

<script>
import FeedbackDetailController from "./FeedbackDetailController";
export default FeedbackDetailController;
</script>

<style scoped>
span.badge {
  border-radius: 30px;
}
</style>