<template>
  <ConfirmationAlert />
  <NotificationToast />
  <ErrorAlertComponent />
  <TermsComponent />
  <OffcanvasViews />
  <router-view />
</template>

<script lang="ts">
import ErrorAlertComponent from "@/components/ErrorAlertComponent.vue";
import NotificationToast from "@/components/NotificationToast.vue";
import OffcanvasViews from "@/components/Offcanvas/OffcanvasViews/OffcanvasViews.vue";
import TermsComponent from "@/components/TermsComponent.vue";
import { computed, defineComponent, onMounted } from "vue";
import { toggleOffcanvas } from "./components/Offcanvas/OffcanvasComponent.vue";
import { startFirebase } from "./firebaseinit";
import { useMainStore } from "./store";
export default defineComponent({
  setup() {
    const store = useMainStore();
    const offcanvasPath = computed(() => store?.offcanvas_config?.path);
    onMounted(() => {
      if (offcanvasPath.value) {
        toggleOffcanvas("offcanvas_views");
      }
    });
  },
  mounted() {
    if (useMainStore().session) {
      this.$userService.syncSession();
    }
    this.$pusher.connect();
    startFirebase();
  },
  beforeUnmount() {
    this.$pusher.disconnect();
  },
  created() {
    document.title = process.env.VUE_APP_APP_NAME;
  },
  components: {
    ErrorAlertComponent,
    NotificationToast,
    TermsComponent,
    OffcanvasViews,
  },
});
</script>

<style>
body {
  background-color: #fff !important;
}
</style>
