import { toggleModal } from "@/components/ModalComponent.vue";
import { useMainStore } from "@/store";
import { isCPFValid, isNameValid } from "@/utils/FormValidators";
import { phoneCodes } from "@/utils/phoneCodes";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import AccountDelete from "./AccountDelete/AccountDelete.vue";
import AccountUpdate from "./AccountUpdate/AccountUpdate.vue";
import PasswordUpdate from "./PasswordUpdate/PasswordUpdate.vue";
import { EOffcanvasViews, navigateCanvas } from "@/components/Offcanvas/OffcanvasViews/OffcanvasViewsController";

export default defineComponent({
    setup() {
        const store = useMainStore()
        const { session } = storeToRefs(store)
        return {
            v$: useVuelidate(),
            userSession: session
        }
    },
    computed: {
        documentNumber(): string {
            
            const number = this.userSession?.document_number?.clear()
            return isCPFValid(number) ? number.conformToPattern('cpf') : this.userSession?.document_number
        }

    },
    data() {
        return {
            phoneCodes: phoneCodes,
            accountForm: {
                name: '',
                country_code: '',
                phone: '',
                email: '',
            },
            phoneMask: '(##) #####-####',
            phoneCode: phoneCodes[0] as any,
            navigateCanvas: navigateCanvas,
            EOffcanvasViews: EOffcanvasViews
        }
    },
    validations() {
        return {
            accountForm: {
                name: {
                    required: helpers.withMessage('Informe um nome válido', required),
                    minLength: helpers.withMessage('Informe um nome válido', minLength(3)),
                    fullName: helpers.withMessage('Informe um nome válido', (value: string) => isNameValid(value)),
                },
                country_code: {
                    required: helpers.withMessage('Informe o DDI', required),
                },
                phone: {
                    required: helpers.withMessage('Informe um telefone válido', required),
                    minLength: helpers.withMessage('Informe um telefone válido', minLength(this.phoneCode?.mask.length)),
                },
            }
        }
    },
    methods: {
        handleToggleModal(id: string) {
            toggleModal(id)
        },
    },
    components: {
        AccountUpdate,
        PasswordUpdate,
        AccountDelete
    }
})