import moment from 'moment';
import NetworkService, { Endpoint } from "./NetworkService"
import { IChatNewMessage } from '@/interfaces';

export default class ChatService {
	network
	static DEFAULT_MESSAGE = 'Esta conversa foi criada automaticamente\ncom psi após a confirmação do agendamento'
	constructor() {
		this.network = new NetworkService()
	}
	async list(params: any = {}) {
		let res = await this.network.get(Endpoint.chats, params)
		res.data = res.data.map((r: any) => {
			return this.getFormattedMessage(r)
		}).sort((a: any, b: any) => {
			if (a.lastMessageDateTimestamp > b.lastMessageDateTimestamp) {
				return -1
			}
			if (a.lastMessageDateTimestamp < b.lastMessageDateTimestamp) {
				return 1
			}
			return 0
		})
		return res
	}
	sendMessage(params: IChatNewMessage) {
		return this.network.post(Endpoint.chatMessages, params)
	}
	getMessages(chatId: any) {
		return this.network.get(`${Endpoint.chatMessages}`, { appointment_chat_id: chatId })
	}
	getFormattedMessage(message: any) {
		try {
			const d = message.latest_message ? message.latest_message.created_at : message.created_at
			message.formattedDate = d.toFormattedDate(1)
			message.formattedLatestMessage = message.latest_message ? message.latest_message.message : ChatService.DEFAULT_MESSAGE
			message.lastMessageDateTimestamp = moment(d).valueOf()
		} catch (e) {
			console.error(e);
		} finally {
			return message
		}

	}
}
